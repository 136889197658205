import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

import * as S from '../components/Post/style';
import ProjectCard from '../components/ProjectCard';
import {
  Css3,
  Docker,
  Express,
  Flyway,
  Gatsby,
  Gradle,
  Graphql,
  Hibernate,
  Html5,
  Java,
  Javascript,
  Jsonwebtokens,
  Junit5,
  Mysql,
  Netlify,
  Nodedotjs,
  Python,
  ReactLogo,
  Springboot,
  Springsecurity,
  Sqlite,
  Styledcomponents,
  Terraform,
  Typescript,
  Vite,
  Yarn
} from 'styled-icons/simple-icons';
import { Aws } from 'styled-icons/fa-brands';

const PortfolioPage = () => (
  <Layout>
    <SEO title="Portfolio" />
    <S.PostHeader>
      <S.PostTitle>My Projects</S.PostTitle>
    </S.PostHeader>
    <S.MainContent>
      <ProjectCard
        title="Verxiom"
        description={
          <p>
            {'Single-page application for localization management that leverages Spring Security to provide administrators with fine-grained access control over their projects. ' +
              'You can read more about it '}
            <a href="/continuous-localization-saas-with-spring-boot-react-js-and-aws">
              here
            </a>
            .
          </p>
        }
        url="https://verxiom.com"
        stack={[
          { name: 'AWS', icon: <Aws /> },
          { name: 'Terraform', icon: <Terraform /> },
          { name: 'Java', icon: <Java /> },
          { name: 'Gradle', icon: <Gradle /> },
          { name: 'Spring Boot', icon: <Springboot /> },
          { name: 'Spring Security', icon: <Springsecurity /> },
          { name: 'JWT', icon: <Jsonwebtokens /> },
          { name: 'Hibernate', icon: <Hibernate /> },
          { name: 'Junit 5', icon: <Junit5 /> },
          { name: 'MySQL', icon: <Mysql /> },
          { name: 'Flyway', icon: <Flyway /> },
          { name: 'Docker', icon: <Docker /> },
          { name: 'JavaScript', icon: <Javascript /> },
          { name: 'TypeScript', icon: <Typescript /> },
          { name: 'Yarn', icon: <Yarn /> },
          { name: 'React', icon: <ReactLogo /> },
          { name: 'Vite', icon: <Vite /> },
          { name: 'Styled Components', icon: <Styledcomponents /> },
          { name: 'HTML 5', icon: <Html5 /> },
          { name: 'CSS 3', icon: <Css3 /> }
        ]}
      />

      <ProjectCard
        title="My personal website"
        description={
          <p>
            {'This website is a '}
            <a
              href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
              target="_blank"
              rel="noreferrer"
            >
              PWA
            </a>
            {'-compliant application engineered for '}
            <a
              href="https://developer.mozilla.org/en-US/docs/Glossary/SEO"
              target="_blank"
              rel="noreferrer"
            >
              SEO
            </a>
            {
              ' which integrates with a repository of blog entries by means of a GraphQL API. You can read more about it '
            }
            <a href="/hello-world">here</a>.
          </p>
        }
        url="https://eber.xyz"
        stack={[
          { name: 'Netlify', icon: <Netlify /> },
          { name: 'JavaScript', icon: <Javascript /> },
          { name: 'TypeScript', icon: <Typescript /> },
          { name: 'Yarn', icon: <Yarn /> },
          { name: 'React', icon: <ReactLogo /> },
          { name: 'Gatsby', icon: <Gatsby /> },
          { name: 'GraphQL', icon: <Graphql /> },
          { name: 'Styled Components', icon: <Styledcomponents /> },
          { name: 'HTML 5', icon: <Html5 /> },
          { name: 'CSS 3', icon: <Css3 /> }
        ]}
      />

      <ProjectCard
        title="Medikonline"
        description={
          <p>
            A pet project inspired by remote health care to demonstrate the use
            of WebSockets in instant messaging.
          </p>
        }
        repoUrls={[
          'https://github.com/eberjoe/medikonline-web',
          'https://github.com/eberjoe/medikonline-core'
        ]}
        url="https://medikonline-web.onrender.com"
        stack={[
          { name: 'JavaScript', icon: <Javascript /> },
          { name: 'Yarn', icon: <Yarn /> },
          { name: 'Node', icon: <Nodedotjs /> },
          { name: 'Express', icon: <Express /> },
          { name: 'SQLite', icon: <Sqlite /> },
          { name: 'JWT', icon: <Jsonwebtokens /> },
          { name: 'React', icon: <ReactLogo /> },
          { name: 'Styled Components', icon: <Styledcomponents /> },
          { name: 'HTML 5', icon: <Html5 /> },
          { name: 'CSS 3', icon: <Css3 /> }
        ]}
      />

      <ProjectCard
        title="Tradocs"
        description={
          <p>
            {'This utility translates natural language present in '}
            <a
              href="https://dotnet.github.io/docfx/"
              target="_blank"
              rel="noreferrer"
            >
              Docfx
            </a>
            {' source code, preserving markup sintax.'}
          </p>
        }
        repoUrls={['https://github.com/eberjoe/tradocs']}
        url="https://pypi.org/project/tradocs"
        stack={[{ name: 'Python', icon: <Python /> }]}
      />
    </S.MainContent>
  </Layout>
);

export default PortfolioPage;
