import styled from 'styled-components';
import media from 'styled-media-query';

export const TechWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  ${media.lessThan('medium')`
    font-size: 0.6rem
  `}
`;

export const IconWrap = styled.div`
  margin: 0.5rem;
  width: 2rem;
  height: 2rem;
`;
