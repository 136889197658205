import styled from 'styled-components';
import media from 'styled-media-query';

export const CardWrap = styled.div`
  padding: 24px;
  border-radius: 8px;
  position: relative;
  background: var(--projectCard);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: var(--projectCardText);
  margin: 1rem;
  ${media.greaterThan('large')`
    :hover {
      background: var(--projectCardHover);
    }
  `}
  p {
    color: var(--projectCardText);
    margin-bottom: 16px;
    line-height: 21px;
    font-size: 16px;

    ${media.lessThan('medium')`
      margin-bottom: 5px;
    `};
  }
  a {
    color: var(--projectCardLink);
  }
  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
    list-style: none;
    color: var(--projectCardText);
    ${media.lessThan('large')`
      grid-template-columns: repeat(2, 1fr);
    `}
  }
`;

export const TitleWrap = styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`;

export const StyledTable = styled.table`
  width: 100%;
`;

export const LeftTd = styled.td`
  white-space: nowrap;
`;

export const RightAlignedTd = styled.td`
  text-align: right;
`;

export const StyledP = styled.p`
  padding: 0;
  margin: 0;
`;
