import React, { ReactElement } from 'react';
import * as S from './style';
import { Tech } from '../../models';
import TechLabel from '../TechLabel';

type ProjectCardProps = {
  title: string;
  description: ReactElement;
  stack: Tech[];
  repoUrls?: string[];
  url?: string;
};

const ProjectCard = ({
  title,
  description,
  stack,
  repoUrls,
  url
}: ProjectCardProps) => (
  <S.CardWrap>
    <S.TitleWrap>{title}</S.TitleWrap>
    {description}
    <S.StyledTable>
      <tbody>
        {repoUrls && (
          <tr>
            <S.LeftTd>
              <S.StyledP>Repos:</S.StyledP>
            </S.LeftTd>
            <S.RightAlignedTd>
              {repoUrls.map((url) => (
                <S.StyledP key={url}>
                  <a href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </S.StyledP>
              ))}
            </S.RightAlignedTd>
          </tr>
        )}
        <tr>
          <S.LeftTd>
            <S.StyledP>URL:</S.StyledP>
          </S.LeftTd>
          <S.RightAlignedTd>
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </S.RightAlignedTd>
        </tr>
      </tbody>
    </S.StyledTable>
    <ul>
      {stack.map((tech) => (
        <li key={tech.name}>
          <TechLabel name={tech.name} icon={tech.icon} />
        </li>
      ))}
    </ul>
  </S.CardWrap>
);

export default ProjectCard;
