import React, { ReactElement } from 'react';
import * as S from './style';

type TechLabelProps = {
  name: string;
  icon: ReactElement;
};

const TechLabel = ({ name, icon }: TechLabelProps) => (
  <S.TechWrap title={name}>
    <S.IconWrap>{icon}</S.IconWrap>
    {name}
  </S.TechWrap>
);

export default TechLabel;
